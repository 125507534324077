import React, { memo, useContext } from 'react';
import {Badge} from 'antd';
import '../../../assets/chat/dialogue-list-item-title.scss'
import ApplicationExistsInfo from './ApplicationExistsInfo';
import AssignOperator from '../../AssignOperator/components/AssignOperator';
import { UserDataContext } from '../../../contexts/context';

const DialogueListItemTitle = memo(({applicantName, messageCreationTime, messageCreationDate, appsCount, dialogue, dialogueSnapId}) => {
  const { features } = useContext(UserDataContext);

  return (
   <div className="dialogue-list__card-title">
    <p className="card-title__client-name">
      {applicantName}
    </p>
    <div className="div">
      <ApplicationExistsInfo appsCount={appsCount}/>
    </div>
    <div className="div">
      {features.canAssignOperator ? (
        <AssignOperator
          clientId={dialogue.UID}
          assignedTo={dialogue.assignedTo}
          transparent={false}
          dialogue={dialogue}
          dialogueSnapId={dialogueSnapId}
        />
      ) : (
        null
      )}
    </div>
    <div className="card-title__service-info">
      <Badge count={dialogue.unreadMessagesForCRM} classNames={{root:"card-title__unread", indicator:"unread__indicator"}}/>
      <div className="card-title__last-message-info">
        <div className="card-title__last-message-date">{messageCreationDate}</div>
        <div className="card-title__last-message-time">{messageCreationTime}</div>
      </div>
    </div>
   </div>
  );
});

export default DialogueListItemTitle;
