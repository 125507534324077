import { Button, Form, InputNumber, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import { useCompany, useUpdateCompany } from '../../../pages/Company/providers/CompanyProvider';
import Spinner from '../../../components/Spinner';
import { useUpdateFinanceOperations } from '../providers/CompanyFinanceProvider';
import '../../../assets/company-finance/company-finance.scss'
import ReconciliationStatement from './ReconciliationStatement';
import { replenishAccount } from '../../../firebase/companies/companies';
import showNotification from '../../NotificationService/helpers/showNotification';
import { useParams } from 'react-router-dom';
import { ProgramContext, UserDataContext } from '../../../contexts/context';

const CURRENCY = 'KZT';

const CompanyFinance = () => {
  const { companyId } = useParams();
  const { authorizedUser } = useContext(UserDataContext);
  const { companyData, companyLoading, companyError } = useCompany();
  const [ replenishModalOpened, setReplenishModalOpened ] = useState(false)
  const { notificationAPI } = useContext(ProgramContext);
  const { companyReload } = useUpdateCompany();
  const { operationsReload, reloadBalanceResult  } = useUpdateFinanceOperations()
  const { features } = useContext(UserDataContext);

  const [form] = Form.useForm();

  const handleModalOpen = () => {
    setReplenishModalOpened(true)
  }

  const handleModalCancel = () => {
    setReplenishModalOpened(false)
  }

  const handleModalOk = () => {
    form.submit();
  }

  const formSubmit = async (values) => {
    try {
      setReplenishModalOpened(false);
      await replenishAccount(companyId, values.amount, authorizedUser);
      showNotification(notificationAPI, 'process', {processName: 'replenishCompanyAccount', status:'success'})
      companyReload();
      operationsReload();
      reloadBalanceResult();
    } catch (error) {
      console.log(error)
      showNotification(notificationAPI, 'process', {processName: 'replenishCompanyAccount', status:'error'})
    }
  }

  return (
    <div className='company-finance'>
      {companyError && 'Ошибка'}
      {companyLoading && <Spinner />}
      {(!companyLoading && !companyError) && (
        <>
          <div className="company-finance__head">
            <h2 className="company-finance__title">
              Акт сверки
            </h2>
            <div className="company-finance__balance-info">
              <div className="company-finance__balance">
                {`Текущий баланс: ${companyData.balance[CURRENCY]} ${CURRENCY}`}
              </div>
              {features.canReplenishAccount ? (
                <>
                  <Button type='primary' size='large' onClick={handleModalOpen}>Пополнить баланс</Button>
                  <Modal
                    open={replenishModalOpened}
                    onOk={handleModalOk}
                    onCancel={handleModalCancel}
                  >
                    <Form
                      form={form}
                      preserve={false}
                      onFinish={formSubmit}
                    >
                      <Form.Item
                        name='amount'
                        label='Сумма'
                      >
                        <InputNumber min={1} />
                      </Form.Item>
                    </Form>
                  </Modal>
                </>
              ) : null}

            </div>
            <div className='plug'></div>
          </div>
          <ReconciliationStatement/>
        </>
      )}
    </div>
  );
};

export default CompanyFinance;