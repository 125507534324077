import React from 'react';
import { Select, Space, Typography } from 'antd';
import styles from './CustomSelect.module.scss';

const CustomSelect = ({title, selectProps}) => {
  return (
    <div className={styles['custom-select']}>
      <Space direction='vertical' size={'small'}>
        {title && (
          <Typography >{title}</Typography>
        )}
        <Select
          {...selectProps}
          style={{minWidth: '160px'}}
        />
      </Space>
    </div>
  );
};

export default CustomSelect;