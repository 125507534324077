export const appTypes = {
  all: {
    value: 'all',
    label: 'Все'
  },
  corporate: {
    value: 'corporate',
    label: 'Корпоративные'
  },
  //TODO: Сперва добавить в заявки поле paymentType: 'regular' или типа того. Обратное от корпоративной.
  // regular:{
  //   value: 'regular',
  //   label: 'От физ. лиц'
  // },
}

export const appTypesSelectOptions = [
  {
    value:appTypes.corporate.value,
    label:appTypes.corporate.label,
  },
  //TODO: Сперва добавить в заявки поле paymentType: 'regular' или типа того. Обратное от корпоративной.
  // {
  //   value:appTypes.regular.value,
  //   label:appTypes.regular.label,
  // },
  {
    value:appTypes.all.value,
    label:appTypes.all.label,
  },
]

const DEFAULT_APP_TYPE_SELECTED = appTypes.all.value
