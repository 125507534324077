import React from 'react';
import styles from './CorpText.module.scss';
import { Tag } from 'antd';

const CorpText = ({clientData}) => {
  return (
    <div className={styles['corp-text']} >
      Корп: {clientData?.company ? <Tag color="green" rootClassName={styles['corp-text__tag']}>{`${clientData.company.companyName.organizationType} ${clientData.company.companyName.main} ${clientData.company.companyName.additionalDescription}`}</Tag> : ''}
    </div>
  );
};

export default CorpText;