export const items = [
  {
    key: 'name',
    label: 'Название ТОО'
  },
  {
    key: 'phone',
    label: 'Телефон'
  },
  {
    key: 'email',
    label: 'Email'
  },
  {
    key: 'actualAdress',
    label: 'Фактический адрес'
  },
  {
    key: 'legalAdress',
    label: 'Юридический адрес'
  },
  {
    key: 'BIN',
    label: 'БИН'
  },
  {
    key:'employeesNumber',
    label:'Кол-во сотрудников'
  }
]