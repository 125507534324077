import React, { useContext, useState } from 'react';
import { useCompany, useUpdateCompany } from '../../Company/providers/CompanyProvider';
import '../../../assets/company-info/company-info.scss'
import CompanyManagerCard from '../ui/CompanyManagerCard';
import { Button } from 'antd';
import CompanyInfoFields from '../../../modules/CompanyInfoFields/components/CompanyInfoFields';
import CompanyInfoForm from '../../../modules/CompanyInfoFields/components/CompanyInfoForm';
import showNotification from '../../../modules/NotificationService/helpers/showNotification';
import { ProgramContext } from '../../../contexts/context';

const CompanyInfo = () => {
  const { companyData, companyLoading, companyError } = useCompany();
  const { updateCompanyData, companyReload } = useUpdateCompany();
  const [ isEdit, setIsEdit ] = useState(false);
  const { notificationAPI } = useContext(ProgramContext);

  const handleEdit = () => {
    setIsEdit(prev => !prev)
  }

  const handleUpdateCompany = async (newData) => {
    try {
      await updateCompanyData(newData)
      await companyReload();
      showNotification(notificationAPI, 'process', {processName: 'updateCompany', status:'success'})
    } catch (e) {
      showNotification(notificationAPI, 'process', {processName: 'updateCompany', status:'error'})
    }
  }

  const cancelChanges = () => {
    setIsEdit(false)
  }

  const Component = isEdit ? (
    <CompanyInfoForm companyData={companyData} handleUpdateCompany={handleUpdateCompany} cancelChanges={cancelChanges} stopEdit={handleEdit}/>
  ) : (
    <CompanyInfoFields companyData={companyData}/>
  );

  return (
    <div style={{display:'flex',justifyContent: 'center', gap: '3rem', backgroundColor:'white', padding:'1rem'}}>

      <div className='company-info' >
        {companyLoading ? "Загрузка..." : Component}
      </div>
      <div>
        <CompanyManagerCard />
        <Button type='link' onClick={handleEdit}>Редактировать</Button>
      </div>
    </div>
  );
};

export default CompanyInfo;