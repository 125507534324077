import React, { useCallback, useContext, useMemo } from 'react';
import getCompanyApplicationColumns from '../models/companyApplicationsColumns';
import { useCompanyApplications, useUpdateCompany } from '../../../pages/Company/providers/CompanyProvider';
import { Table } from 'antd';
import showNotification from '../../NotificationService/helpers/showNotification';
import { ProgramContext } from '../../../contexts/context';
import { approveApplication } from '../../../firebase/companies/companies';
import { useUpdateFinanceOperations } from '../../CompanyFinance/providers/CompanyFinanceProvider';

const CompanyApplications = () => {
  const { applications, applicationsLoading, applicationsError } = useCompanyApplications();
  const { notificationAPI } = useContext(ProgramContext);
  const { reloadApplications, companyReload  } = useUpdateCompany();
  const { operationsReload, reloadBalanceResult } = useUpdateFinanceOperations()

  const handleApprove = useCallback(async (record) => {
    try {
      await approveApplication(record);
      reloadApplications();
      companyReload();
      operationsReload();
      reloadBalanceResult();
      showNotification(notificationAPI, 'process', {processName: 'approveApplication', status:'success'})
    } catch (error) {
      showNotification(notificationAPI, 'process', {processName: 'approveApplication', status:'error'})
    }
  },[companyReload, notificationAPI, operationsReload, reloadApplications, reloadBalanceResult])

  const columns = useMemo(() => {
    return getCompanyApplicationColumns(handleApprove);
  }, [handleApprove])

  console.log(columns)

  return (
    <div style={{overflowWrap:'break-word'}}>
      {applicationsError && applicationsError}
      {(!applicationsLoading && !applicationsError) && (
        <Table
          columns={columns}
          dataSource={applications}
          size='small'
          loading={applicationsLoading}
          pagination={false}
        />
      )}
    </div>
  );
};

export default CompanyApplications;