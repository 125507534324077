import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { items } from './fields';
import ApplyOrCancel from '../../../components/ApplyOrCancel';

const CompanyInfoForm = ({companyData, handleUpdateCompany, cancelChanges, stopEdit}) => {
  const [form] = Form.useForm();
  const [ formIsLoading, setFormIsLoading ] = useState(false);

  const formFinish = async (values) => {
    try {
      setFormIsLoading(true)
      const { 0: organizationType,  1: main, 2: additionalDescription } = values.name.trim().split(' ');
      const newValues = {
        ...values,
        employeesNumber: +values.employeesNumber,
        name: {
          additionalDescription,
          main,
          organizationType,
        }
      }
      await handleUpdateCompany(newValues)
      stopEdit();
    } catch (error) {
      console.log(error)
    } finally {
      setFormIsLoading(false)
    }
  }

  const applyChanges = () => {
    form.submit()
  }

  const pressEnter = (e) => {
    if(e.key === 'Enter') {
      applyChanges()
    }
  }

  return (
    <Form style={{minWidth:'500px'}} form={form} onFinish={formFinish} onKeyDown={pressEnter} >
      {items.map(item => {
        let itemData = companyData[item.key]
          if(item.key === 'name') {
            itemData = `${companyData.name.organizationType} ${companyData.name.main} ${companyData.name.additionalDescription}`
          }
        return (
          <Form.Item
            name={item.key}
            initialValue={itemData}
            label={item.label}
          >
            <Input/>
          </Form.Item>
        )
      })}
      <ApplyOrCancel
        isEdit={true}
        applyChanges={applyChanges}
        cancelChanges={cancelChanges}
        loading={formIsLoading}
      />
    </Form>
  );
};

export default CompanyInfoForm;