import React from 'react';
import { items } from './fields';



const getCompanyInfoItems = (companyData) => {
  return items.map(item => {
    let itemData = companyData[item.key]
    if(item.key === 'name') {
      itemData = `${companyData.name.organizationType} ${companyData.name.main} ${companyData.name.additionalDescription}`
    }
    return (
      <div className='company-info__item' key={item.key}>
        <span className='company-info__label'>{`${item.label}: `}</span>
        <span>{itemData}</span>
      </div>
    )
  })
}

const CompanyInfoFields = ({companyData}) => {
  return (
    <div >
      {getCompanyInfoItems(companyData)}
    </div>
  );
};

export default CompanyInfoFields;