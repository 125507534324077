import React from "react";
import {Link} from "react-router-dom";
import GLOBAL_ROLES from "../../../constants/constants";
import AssignOperator from "../../AssignOperator/components/AssignOperator";
import PaymentIcon from "../../../components/PaymentIcon";
import getStatusesFilters from "../../../models/getStatusesFilters";
import getCountriesFilters from "../../../models/getCountriesFilters";
import getPaymentFilters from "../../../models/getPaymentFilters";
import AssignedToCustomizedFilter from "../../../components/AssignedToTableFilter/AssignedToCustomizedFilter";
import getAssignedToFilterData from '../../../models/getAssignedToFilterData';
import TableStatusTag from "../../../components/TableStatusTag";
import getFromLocalStorage from "../../../localStorage/getFromLocalStorage";
import ApplicationIdCell from "../components/ApplicationIdCell";

const admin = GLOBAL_ROLES.admin;
const all = "all";

const roleBasedContent = {
  admin: {
    columnsIncluded: ["all", "admin"],
  },
  operator:{
    columnsIncluded: ["all", "operator"],
  },
  superOperator: {
    columnsIncluded: ["all", "admin"],
  },
  careSupport: {
    columnsIncluded: ["all", "admin"], // TODO: рефакторить все.
  }

}

const createStatusTag = (status) => {
  return <TableStatusTag status={status}/>;
}
const renderPaymentIcon = (text) => {
  return <PaymentIcon payment={text} />
}
const countrySearch = (input, record) => {
  return record.text.toLowerCase().includes(input.toLowerCase())
}


const allColumns = {
  id: {
    role: all,
    isSelectedDefault: true,
    config: {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: "center",
      width: 70,
      render: (text, record, _) => {
        const isCorporate = record.application.paymentType === 'corporate';
        return (
          <ApplicationIdCell
            UID={record.application.UID}
            documentID={record.application.documentID}
            text={text}
            isCorporate={isCorporate}
          />
        )
      },
    }
  },
  countryFullName: {
    role: all,
    isSelectedDefault: true,
    config: {
      title: 'Country',
      dataIndex: 'countryFullName',
      key: 'countryFullName',
      align: "center",
      ellipsis: true,
      filters:[], // запишется после загрузки стран в функции getColumnsConfig.
      filterSearch: countrySearch,
    }
  },
  date: {
    role: all,
    isSelectedDefault: true,
    config: {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: "center",
      // filterDropdown: (props) => <DateFilter filterDropdownProps={props}/>,
    }
  },
  iin: {
    role: all,
    isSelectedDefault: false,
    config: {
      title: 'IIN',
      dataIndex: 'iin',
      key: 'iin',
      align: "center",
    }
  },
  paymentDate: {
    role: all,
    isSelectedDefault: false,
    config: {
      title: 'Payment date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      align: "center",
    }
  },
  applicant: {
    role: all,
    isSelectedDefault: true,
    config: {
      title: 'Applicant',
      dataIndex: 'applicant',
      key: 'applicant',
      align: "center",
    }
  },
  citizenship: {
    role: all,
    isSelectedDefault: true,
    config: {
      title: 'Citizenship',
      dataIndex: 'citizenship',
      key: 'citizenship',
      align: "center",
    }
  },
  email: {
    role: all,
    isSelectedDefault: true,
    config: {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: "center",
      // ellipsis: true,
      // width: "200px",
    }
  },
  phone: {
    role: all,
    isSelectedDefault: true,
    config: {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      align: "center",
    }
  },
  applicants: {
    role: all,
    isSelectedDefault: true,
    config: {
      title: 'Applicants number',
      dataIndex: 'applicants',
      key: 'applicants',
      align: "center",
      width:85,
    }
  },
  status: {
    role: all,
    isSelectedDefault: true,
    config: {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: "center",
      width: 90,
      render: (_, record) => {
        return createStatusTag(record.application.preparedInformation.preparationStatus)
      },
      filters: getStatusesFilters(),
    }
  },
  payment: {
    role: all,
    isSelectedDefault: false,
    config: {
      title:'Payment',
      dataIndex: 'payment',
      key: 'payment',
      align: 'center',
      width:90,
      render: renderPaymentIcon,
      filters: getPaymentFilters(),
    }
  },
  totalPrice: {
    role: all,
    isSelectedDefault: true,
    config: {
      title:'Total price',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      align: 'center',
    }
  },
  assignedTo: {
    role: admin,
    isSelectedDefault: true,
    config: {
      title: 'Viser',
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      align: "center",
      filterMode: 'tree',
      filterDropdown: (props) => <AssignedToCustomizedFilter filterProps={props}/>,
      render: (_text, record, _index) => {
        const assignedTo = record.application.preparedInformation.assignedTo;
        const status = record.application.preparedInformation.preparationStatus;
        const disabledProp = status > 4;
        return (
          <AssignOperator
            clientId={record.application.UID}
            assignedTo={assignedTo}
            disabledProp={disabledProp}
          />
        )
      },

    }
  }
};

export const getColumnsConfig = (columnsToDisplay, filters, countries=[], employeesData=[]) => {
  return columnsToDisplay.reduce((acc, columnInfo) => {
    if(!columnInfo.isSelected) {
      return acc;
    }
    if(columnInfo.value === 'countryFullName') {
      acc.push({...allColumns[columnInfo.value].config, filters: getCountriesFilters(countries, filters), defaultFilteredValue: filters.countryFullName})
      return acc;
    }
    if(columnInfo.value === 'assignedTo') {
      acc.push({...allColumns[columnInfo.value].config, filters: getAssignedToFilterData('assignedEmployeesFilter', employeesData), defaultFilteredValue: filters.assignedTo})
      return acc;
    }
    if(columnInfo.value === 'status') {
      acc.push({...allColumns[columnInfo.value].config, defaultFilteredValue: filters.status})
      return acc;
    }
    if(columnInfo.value === 'payment') {
      acc.push({...allColumns[columnInfo.value].config, defaultFilteredValue: filters.payment})
      return acc;
    }
    acc.push(allColumns[columnInfo.value].config);
    return acc;
  }, [])
}

export const getDefaultSetOfColumns = (role) => {
  const arr = Object.keys(allColumns);

  return arr.reduce((acc, key) => {
    if(!roleBasedContent[role].columnsIncluded.includes(allColumns[key].role)) { // если роль оператора не поддерживается колонкой, то игнорируем эту колонку.
      return acc;
    }
    acc.push({
      label: allColumns[key].config.title,
      value: allColumns[key].config.key,
      isSelected: allColumns[key].isSelectedDefault,
    });
    return acc;
  }, [])
}

export const setOfColumns = (authorizedUser, columnsType) => {
  const { role, id } = authorizedUser;
  if(role === 'assistant') {
    return;
  }
  const storagedSetOfColumns = getFromLocalStorage(id, columnsType);
  if(storagedSetOfColumns) {
    return storagedSetOfColumns; // если в localstorage есть сохраненный набор колонок - установить его.
  }

  return getDefaultSetOfColumns(role);
}

export default allColumns;
