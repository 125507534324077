import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useUnsentMessagesData from '../hooks/useUnsentMessagesData';
import useMessagesData from '../hooks/useMessagesData';
import useClientData from '../hooks/useClientData';
import useCompanyData from '../../../pages/Company/hooks/useCompanyData';

const MessagesDataContext = createContext({});
const UnsentMessagesDataContext = createContext({});
const ClientDataContext = createContext({});
const UpdateChatContext = createContext({});

const ChatDataProvider = ({UID, selectedChatSnapId, children}) => {
  const [ messagesPages, setMessagesPages ] = useState(1);
  const [ unsentMessages, addUnsentMessage, removeUnsentMessage ] = useUnsentMessagesData(UID);
  const [ messagesData, messagesFirstLoading, messagesError, addTempMessage ] = useMessagesData(UID, selectedChatSnapId, messagesPages, unsentMessages, removeUnsentMessage); // должен принять id выбранного чата. Если нет его то что то обработать
  const [ clientData, clientDataLoading, clientDataError ] = useClientData(UID);

  useEffect(() => {
    setMessagesPages(1)
  }, [UID])

  const messagesDataContext = useMemo(() => {
    return {
      messagesPages,
      messagesData,
      messagesFirstLoading,
      messagesError,
      addTempMessage,
    }
  }, [addTempMessage, messagesData, messagesError, messagesFirstLoading, messagesPages])

  const unsentMessagesDataContext = useMemo(() => {
    return {
      unsentMessages,
      addUnsentMessage,
      removeUnsentMessage
    }
  }, [addUnsentMessage, removeUnsentMessage, unsentMessages])

  const clientDataContext = useMemo(() => {
    return {
      clientData,
      clientDataLoading,
      clientDataError,
    }
  }, [clientData, clientDataError, clientDataLoading])

  const updateChatContext = useMemo(() => {
    return {
      setMessagesPages,
    }
  }, [])

  return (
    <MessagesDataContext.Provider value={messagesDataContext}>
      <UnsentMessagesDataContext.Provider value={unsentMessagesDataContext}>
        <ClientDataContext.Provider value={clientDataContext}>
          <UpdateChatContext.Provider value={updateChatContext}>
            {children}
          </UpdateChatContext.Provider>
        </ClientDataContext.Provider>
      </UnsentMessagesDataContext.Provider>
    </MessagesDataContext.Provider>
  );
};

export default ChatDataProvider;

export const useMessagesContext = () => useContext(MessagesDataContext);
export const useClientContext = () => useContext(ClientDataContext)
export const useUnsentMessagesContext = () => useContext(UnsentMessagesDataContext)
export const useUpdateChatContext = () => useContext(UpdateChatContext)
