import React, { createContext, useCallback, useContext, useMemo } from 'react';
import useCompanyData from '../../Company/hooks/useCompanyData';
import useEmployeesData from '../../Company/hooks/useEmployeesData';
import useCompanyApplicationsData from '../../Company/hooks/useCompanyApplicationsData';
import useCompanyManagerData from '../../Company/hooks/useCompanyManagerData';
import { updateCompany } from '../../../firebase/companies/companies';

const CompanyDataContext = createContext({});
const CompanyEmployeesContext = createContext({});
const UpdateCompanyContext = createContext({});
const CompanyApplicationsDataContext = createContext({});
const CompanyManagerContext = createContext({});

const CompanyProvider = ({companyId, children}) => {
  const [ companyData, companyLoading, companyError, companyReload ] = useCompanyData(companyId);
  const [ employeesData, employeesLoading, employeesError, reloadEmployees ] = useEmployeesData(companyId);
  const [ applications, applicationsLoading, applicationsError, reloadApplications ] = useCompanyApplicationsData(companyId);
  const [ companyManager, companyManagerLoading, companyManagerError ] = useCompanyManagerData(companyData?.manager?.id);

  const updateCompanyData = useCallback(async (newData) => {
    await updateCompany(companyId, newData);
  }, [companyId])

  const companyApplicationsDataContext = useMemo(() => {
    return {
      applications,
      applicationsLoading,
      applicationsError
    }
  }, [applications, applicationsError, applicationsLoading])

  const companyDataContext = useMemo(() => {
    return {
      companyData,
      companyLoading,
      companyError,
    }
  }, [companyData, companyError, companyLoading])

  const companyEmployeesContext = useMemo(() => {
    return {
      employeesData,
      employeesLoading,
      employeesError
    }
  }, [employeesData, employeesError, employeesLoading])

  const updateCompanyContext = useMemo(() => {
    return {
      companyReload,
      reloadEmployees,
      reloadApplications,
      updateCompanyData,
    }
  }, [companyReload, reloadEmployees, reloadApplications, updateCompanyData])

  const companyManagerContext = useMemo(() => {
    return {
      companyManager, companyManagerLoading, companyManagerError
    }
  }, [companyManager, companyManagerError, companyManagerLoading])

  return (
    <CompanyDataContext.Provider value={companyDataContext}>
      <CompanyEmployeesContext.Provider value={companyEmployeesContext}>
        <UpdateCompanyContext.Provider value={updateCompanyContext}>
          <CompanyApplicationsDataContext.Provider value={companyApplicationsDataContext}>
            <CompanyManagerContext.Provider value={companyManagerContext}>
              {children}
            </CompanyManagerContext.Provider>
          </CompanyApplicationsDataContext.Provider>
        </UpdateCompanyContext.Provider>
      </CompanyEmployeesContext.Provider>
    </CompanyDataContext.Provider>
  )
};

export default CompanyProvider;

export const useCompany = () => useContext(CompanyDataContext)
export const useCompanyEmployees = () => useContext(CompanyEmployeesContext)
export const useCompanyApplications = () => useContext(CompanyApplicationsDataContext)
export const useUpdateCompany = () => useContext(UpdateCompanyContext)
export const useCompanyManager = () => useContext(CompanyManagerContext)
