import React from 'react';
import '../assets/components/company-title.scss';
import { useCompany } from '../pages/Company/providers/CompanyProvider';

const CompanyTitle = () => {
  const { companyData, companyLoading, companyError } = useCompany();
  return (
    <h2 className='company__title'>
      {companyError && companyError.message}
      {(!companyLoading && !companyError) && `${companyData.name.organizationType} ${companyData.name.main} ${companyData.name.additionalDescription}`}
    </h2>
  );
};

export default CompanyTitle;