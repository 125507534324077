import { useEffect, useState } from 'react';
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import { getCompanyRef } from '../../../firebase/companies/companies';

const useCompanyData = (companyId) => {
  const [ companyDocSnap, companyLoading, companyError, reload ] = useDocumentOnce(getCompanyRef(companyId));
  const [ isLoading, setIsLoading ] = useState(true);
  const [ companyData, setCompanyData ] = useState({});

  const realodCompanyData = () => {
    reload()
  }
  
  useEffect(() => {
    if(!companyId) {
      setIsLoading(false);
    }
  }, [companyId])

  useEffect(() => {
    if(companyDocSnap) {
      setCompanyData({...companyDocSnap.data(), docId: companyDocSnap.id});
      setIsLoading(false);
    }
  }, [companyDocSnap])

  useEffect(() => {
    if(companyError) {
      setIsLoading(false);
    }
  }, [companyError])

  return [
    companyData,
    isLoading,
    companyError,
    realodCompanyData,
  ]
};

export default useCompanyData;