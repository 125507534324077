import React from 'react';
import { Link } from 'react-router-dom';
import { SignatureFilled } from '@ant-design/icons';
import styles from './ApplicationIdCell.module.scss';


const ApplicationIdCell = ({UID, documentID, text, isCorporate}) => {
  return (
    <div className={styles['application-cell']}>
      <Link
        to={`/application/${UID}/${documentID}`}
        style={{ color:"#0EA5E9", fontWeight:"800" }}
      >
        {text}
      </Link>

      {isCorporate && (
        <div className={styles['application-cell__paymen-type-icon']}>
          <SignatureFilled style={{fontSize:'18px'}}/>
        </div>
      )}

    </div>

  );
};

export default ApplicationIdCell;